import signin, { signinIntegradora } from '../signin'
import { getSystemToken, getSystemTokenBFF } from 'utils/systemTokens'
import { decryptLead } from 'utils/decryptLead'

const leadIntegradora = async (user, recaptcha) => {
  const headers = {
    SystemToken: await getSystemTokenBFF(),
    'G-recaptcha': recaptcha,
  }
  const useBusinessParam = user.lead_type === 'PJ'

  return signinIntegradora()
    .then(({ http }) => {
      return http
        .post(`/lead`, user, { headers, useBusinessParam })
        .then((res) => {
          const leadData = decryptLead(res)
          return leadData
        })
        .catch((error) => {
          console.error('lead signin integradora error -', error)
        })
    })
    .catch((error) => {
      console.error('lead signin error -', error)
    })
}

const updateSourceUrl = async ({ last_source_url }, token) => {
  const headers = {
    SystemToken: await getSystemToken(),
  }

  return signin('analytics')
    .then(({ http }) => {
      http.defaults.headers['Token'] = `Bearer ${token}`
      return http
        .put('last_source_url/', { last_source_url }, { headers })
        .then((res) => {
          return res
        })
        .catch((e) => {
          console.error('analytics signin error-', e)
        })
    })
    .catch((error) => {
      console.error('updateSourceUrl error -', error)
    })
}

const setCrossSellTokens = async (tokens) => {
  const headers = {
    SystemToken: await getSystemTokenBFF(),
  }

  return signinIntegradora()
    .then(({ http }) => {
      return http
        .post(`/tokens`, tokens, { headers })
        .then((res) => {
          return res
        })
        .catch((error) => {
          console.error('set cross sell tokens error -', error)
        })
    })
    .catch((error) => {
      console.error('set cross sell tokens error -', error)
    })
}

export default {
  updateSourceUrl,
  leadIntegradora,
  setCrossSellTokens,
}
