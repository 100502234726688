import CryptoJS from 'crypto-js'
import jwt_decode from 'jwt-decode'

import { DECRYPTION_KEY } from 'gatsby-env-variables'

const decryptAES_ECB = (ciphertext, key) => {
  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(ciphertext),
    },
    key,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  )

  const parsedDecrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
  return parsedDecrypted
}

export const decryptLead = (leadData) => {
  if (!leadData?.recovery?.additional && !leadData?.reneg?.additional) {
    return leadData
  }

  const sessionToken = leadData?.access_token

  if (sessionToken?.length) {
    const decodedToken = jwt_decode(sessionToken)

    if (!Object.keys(decodedToken).length) return

    const data = decodedToken?.data

    if (!!data.lead_uuid) {
      const key = `${DECRYPTION_KEY}${data.lead_uuid.substring(0, 8)}`
      const keyHex = CryptoJS.enc.Utf8.parse(key)

      const recoveryAdditional = leadData?.recovery?.additional
      const renegAdditional = leadData?.reneg?.additional

      const recoveryDecrypted = decryptAES_ECB(recoveryAdditional, keyHex)
      const renegDecrypted = decryptAES_ECB(renegAdditional, keyHex)

      const decryptedData = {
        ...leadData,
        recovery: {
          ...leadData.recovery,
          ...recoveryDecrypted,
        },
        reneg: {
          ...leadData.reneg,
          ...renegDecrypted,
        },
      }

      return decryptedData
    }
  }
}
