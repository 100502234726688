import { navigate } from 'gatsby'
import storage from 'utils/storage'
import signin, { signinIntegradora } from '../signin'
import router from 'utils/router'
import jwt_decode from 'jwt-decode'
import { getSystemTokenBFF } from 'utils/systemTokens'
import { DebtGroupBFF, handleSimulatedContracts } from 'entities/DebtGroupBFF'
import { handleValidateTokens } from 'utils/handleTokens'

const getSessionId = () => {
  try {
    const sessionIdURL = router.getQueryString('sessionId')
    if (sessionIdURL) {
      storage.session.setItem('session_id', sessionIdURL)
      return Promise.resolve(sessionIdURL)
    }
  } catch (error) {
    console.error('sessionIdURL -', error)
  }

  try {
    const sessionId = storage.session.getItem('bff-session_id')
    if (sessionId) {
      return Promise.resolve(sessionId)
    }
  } catch (error) {
    console.error('sessionId -', error)
  }

  try {
    const tokenSession =
      router.getQueryString('token') || storage.session.getItem('session_token')
    if (tokenSession) {
      let decoded = jwt_decode(tokenSession)
      decoded = JSON.parse(decoded.data)

      storage.session.setItem('session_id', decoded.session_uuid)

      return Promise.resolve(decoded.session_uuid)
    }
  } catch (error) {
    console.error('getSessionId -', error)
  }
}

const getBFFDebtGroups = async (options = {}) => {
  try {
    const { accessToken, firstName, sessionId } = options

    const { tokenBff } = await handleValidateTokens()

    const storagedDebtGroups = storage.session.getItem(
      `${sessionId}-debt-groups`
    )
    const leadHasAgreements = storage.session.getItem('currentAgreements') || []

    if (storagedDebtGroups) {
      return Promise.resolve(storagedDebtGroups)
    }

    const token =
      accessToken ||
      tokenBff ||
      storage.session.getItem('accessToken') ||
      router.getQueryString('access_token')

    const headers = {
      SystemToken: await getSystemTokenBFF(),
      Token: `Bearer ${token}`,
    }

    const { http: apiIntegradora } = await signinIntegradora()

    const contracts = await apiIntegradora.get('/contracts', { headers })
    const bffSessionId = contracts.session_id
    const recoveryContracts = contracts.origins?.recovery
    const renegContracts = contracts.origins?.reneg

    storage.session.setItem('bff-session_id', bffSessionId)

    if (!contracts || !contracts.origins) {
      return navigate('/error')
    }

    if (!recoveryContracts && !renegContracts) {
      return navigate('/semDivida')
    }

    if (!recoveryContracts && !leadHasAgreements?.length && renegContracts) {
      return navigate('/renegociacao-itau')
    }

    const storagedUserName =
      storage.session.getItem('lead_data')?.lead_name ||
      router.getQueryString('lead_name')
    const userName = firstName || storagedUserName.split(' ')[0]

    if (!recoveryContracts) return []

    const simulationPayload = {
      session_id: bffSessionId,
      groups: recoveryContracts.map((origin) => {
        return {
          payment_date: '',
          contracts: [origin.ExternalContractID],
        }
      }),
      simulation_type: 'all',
    }

    const contractsSimulation = await apiIntegradora.post(
      '/simulation',
      simulationPayload,
      { headers }
    )

    const simulatedContracts = handleSimulatedContracts(
      recoveryContracts,
      contractsSimulation.recovery
    )

    const debtGroups = simulatedContracts.map((simulatedContract) =>
      DebtGroupBFF(simulatedContract)
    )

    const totalValue = simulatedContracts.reduce((acc, simulatedContract) => {
      return acc + simulatedContract.summary.debts_total
    }, 0)

    const data = {
      userName,
      debtGroups,
      totalValue,
      sessionId: bffSessionId,
    }

    storage.session.setItem(`${bffSessionId}-debt-groups`, data)
    storage.session.setItem('groups_selected', data.debtGroups)

    return data
  } catch (error) {
    console.error('getBFFDebtGroups error--', error)
    throw error
  }
}

const sendWhatsAppBySessionId = ({
  cellphone,
  lead_name,
  agreements_values,
}) => {
  return signin().then(({ http }) => {
    return http.post(`system/whatsapp/agreement_data`, {
      cellphone,
      lead_name,
      channel_name: 'Site Recovery',
      agreements_values,
    })
  })
}

export default {
  sendWhatsAppBySessionId,
  getSessionId,
  getBFFDebtGroups,
}
