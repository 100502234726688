import 'styles/main.scss'
import 'utils/loadAsyncSVG'
import React from 'react'
import { GIT_VERSION } from 'gatsby-env-variables'
import isClient from 'utils/isClient'
import { LoaderProvider } from 'hooks/useLoader'

import { StepsProvider } from 'hooks/useSteps.js'
import { ThemeProvider } from 'modules/Theme'
import { NotifyProvider } from 'modules/Notify.js'

if (isClient()) {
  window.__VERSION__ = GIT_VERSION
}

function wrapElement({ element }) {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <StepsProvider>
          <NotifyProvider>{element}</NotifyProvider>
        </StepsProvider>
      </LoaderProvider>
    </ThemeProvider>
  )
}

export default wrapElement
