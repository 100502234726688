/* eslint-disable no-undef */
module.exports = {
  home: {
    path: `/`,
    component: `src/pages/Landing/index.js`,
  },

  register: {
    path: '/registro',
    pageName: 'registro',
    component: `src/pages/LandingRegister/index.js`,
  },

  noDebt: {
    path: '/semDivida',
    pageName: 'semDivida',
    component: `src/pages/NoDebt/index.js`,
  },

  reneg: {
    path: '/renegociacao-itau',
    pageName: 'renegociacaoItau',
    component: `src/pages/Reneg/index.js`,
  },

  ofertas: {
    path: '/ofertas',
    pageName: 'ofertas',
    component: `src/pages/Ofertas/index.js`,
  },

  parcelamento: {
    path: '/parcelamento',
    pageName: 'parcelamento',
    component: `src/pages/Parcelamento/index.js`,
  },

  paymentChoice: {
    path: '/escolha-pagamento',
    pageName: '/escolha-pagamento',
    component: 'src/pages/PaymentChoice/index.js',
  },

  conclusao: {
    path: '/conclusao',
    pageName: 'conclusao',
    component: `src/pages/Conclusao/index.js`,
  },

  error: {
    path: '/error',
    pageName: 'erro',
    component: `src/pages/Error/index.js`,
  },

  seviceUnavailable: {
    path: '/service-unavailable',
    pageName: 'servicoIndisponivel',
    component: `src/pages/404/index.js`,
  },

  skipper: {
    path: `/skipper`,
    pageName: 'skipper',
    component: `src/pages/Skipper/index.js`,
  },

  notFound: {
    path: `/pagina-nao-encontrada`,
    pageName: 'paginaNaoEncontrada',
    component: `src/pages/NotFound/index.js`,
  },

  myAgreements: {
    path: `/meusacordos`,
    pageName: 'meusAcordos',
    component: `src/pages/MyAgreements/index.js`,
  },

  counterOffer: {
    path: `/contraproposta`,
    pageName: 'contraproposta',
    component: `src/pages/CounterProposal/Offer/index.js`,
  },
  counterOfferConclusion: {
    path: `/contraproposta/conclusao`,
    pageName: 'contrapropostaConclusao',
    component: `src/pages/CounterProposal/Conclusion/index.js`,
  },
  counterGenericError: {
    path: `/contraproposta/erro-generico`,
    pageName: 'contrapropostaErroGenerico',
    component: `src/pages/CounterProposal/GenericError/index.js`,
  },
}
