import debt from './debt'
import agreement from './agreement'
import session from './session'
import skipper from './skipper'

export default {
  debt,
  agreement,
  session,
  skipper,
}
