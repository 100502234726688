import storage from 'utils/storage'
import signin, { signinIntegradora } from '../signin'
import entities from 'entities'
import { getSystemTokenBFF } from 'utils/systemTokens'

const toISODate = (str) => {
  return str.split('/').reverse().join('-')
}

const checkGroupsSelected = (groups) => {
  return groups && groups.length !== 0
}

const create = async ({ groupsSelected }) => {
  try {
    const sessionGroupsSelected = storage.session.getItem('groups_selected')

    const groupsSelectedVerified = checkGroupsSelected(groupsSelected)
      ? groupsSelected
      : sessionGroupsSelected

    const groupsToClose = groupsSelectedVerified?.map((group) => ({
      debt_group_id: group.id,
      payment_option_id: group.dueDateSelected.payment.id,
      payment_day: toISODate(group.dueDateSelected.date),
      origin: 'recovery',
      simulation_type:
        group.dueDateSelected.payment.installmentNumber === 1
          ? 'cash'
          : 'installment',
    }))

    if (groupsToClose?.length) {
      const token = storage.session.getItem('accessToken')
      const headers = {
        SystemToken: await getSystemTokenBFF(),
        Token: `Bearer ${token}`,
      }

      const { http: apiIntegradora } = await signinIntegradora()

      const sessionId =
        storage.session.getItem('bff-session_id') ||
        storage.session.getItem('session_id')

      const payload = {
        session_id: sessionId,
        agreements: groupsToClose,
      }

      const agreementsData = await apiIntegradora.post('/agreements', payload, {
        headers,
      })

      const dataAgreements = agreementsData.map(entities.Agreement)

      const defaultCustomData = agreementsData[0]?.custom_data || {}
      const whatsappSentAuto = defaultCustomData?.whatsapp_sent || false
      const leadData = defaultCustomData?.lead_data || null

      storage.session.setItem('agreements', dataAgreements)
      storage.session.setItem('whatsapp_sent_auto', whatsappSentAuto)
      storage.session.setItem('lead_data_contact', leadData)

      return dataAgreements
    } else {
      console.error('crete agreement error - no groups selected')
      return Promise.reject([])
    }
  } catch (error) {
    console.error('crete agreement error', error)
    return Promise.reject(error)
  }
}

const getDetails = () => {
  const sessionId =
    storage.session.getItem('recovery-session_id') ||
    storage.session.getItem('session_id')

  return signin().then(({ http }) => {
    return http.get(`session/${sessionId}/agreements/`).then((data) => {
      const agreementsDetails = data?.agreement_details?.map(
        entities.AgreementDetails
      )
      storage.session.setItem('agreements-details', agreementsDetails)
      return agreementsDetails
    })
  })
}

// Essa função é utilizada para checar se o lead veio de cartões e tem um optin_selected(algum cartão que ele quer aprovação)
const getOptinSelected = () => {
  const sessionId =
    storage.session.getItem('recovery-session_id') ||
    storage.session.getItem('session_id')

  return signin().then(({ http }) => {
    return http.get(`session/${sessionId}/agreements/`).then((data) => {
      const agreementsDetails = data.agreement_details
        ? data.optin_selected
        : false
      return agreementsDetails
    })
  })
}

const getMyAgreements = async (urlWithParams) => {
  try {
    const token = storage.session.getItem('accessToken')
    const headers = {
      SystemToken: await getSystemTokenBFF(),
      Token: `Bearer ${token}`,
    }

    const { http: apiIntegradora } = await signinIntegradora()

    const url = urlWithParams || '/agreements'
    const agreementsResponse = await apiIntegradora.get(url, {
      headers,
    })

    const { recovery } = agreementsResponse

    if (recovery.error) {
      throw recovery.error
    }

    return recovery.agreements
  } catch (error) {
    console.error('getMyAgreements error', error)
    Promise.reject(error)
  }
}

export default {
  create,
  getDetails,
  getOptinSelected,
  getMyAgreements,
}
